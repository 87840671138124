/* @import '~@ngx-share/button/themes/default/default-theme'; */

/*@import "~@ngx-share/button/themes/default/default-theme.css";*/
@import "~@ng-select/ng-select/themes/default.theme.css";
@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Medium.woff2") format("woff2"),
  url("../assets/fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Light.woff2") format("woff2"),
  url("../assets/fonts/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Regular.woff2") format("woff2"),
  url("../assets/fonts/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Thin.woff2") format("woff2"),
  url("../assets/fonts/Roboto-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Bold.woff2") format("woff2"),
  url("../assets/fonts/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
:root {
  --purple-1: #6c2fbb;
  --purple-2: #6e5eff;
  --swiper-pagination-color: var(--purple-2);
  --swiper-pagination-bullet-width: 10px;
  --swiper-pagination-bullet-height: 10px;
  --swiper-pagination-bullet-vertical-gap: 10px;
}

.btn-link {
  color: #673ab7 !important;
}

.btn-link:hover {
  color: #311b92 !important;
}

.landingPage .bg-white {
  background-color: #5721bf !important;
}

.landingPage .hbfilms-top-header .header-logo,
.landingPage .nav-item .my-link {
  color: #fff !important;
}

.landingPage svg path,
.landingPage svg polygon {
  fill: #fff;
}

.landingPage .dropdown-item svg path,
.landingPage .dropdown-item svg polygon {
  fill: #000;
}

.landingPage .hbfilms-top-header form input {
  background: #512294;
  border-color: transparent !important;
  color: #fff;
}

.landingPage #collapsibleNavbar .btn.shadow-none {
  background: transparent;
}

.HB-bg-body {
  background-color: #f5f7f6;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  position: relative !important;
}

/* signup, signin, resetpwd common css start*/

.video-common-form {
  box-shadow: 0px 0px 10px 0px #dad8d8;
  width: 400px;
  padding: 30px;
  background: white;
  border-radius: 2px;
}

.video-common-form .signup-group {
  margin-bottom: 15px;
  position: relative;
}

.video-common-sec {
  display: flex;
  padding-top: 30px;
  justify-content: center;
  align-items: center;
}

.main_focus_input {
  height: 42px;
  background: transparent;
  font-size: 16px;
  color: #5c5c5c;
  font-weight: normal;
  padding-right: 40px;
}

.main_focus_input:focus {
  box-shadow: none;
}

.video-common-form > h4 {
  text-align: center;
  font-size: 20px;
  color: #3c4858;
  margin-bottom: 26px;
}

.video-common-btn {
  border-radius: 5px;
  border: solid 1px #6c2fbb;
  background-color: #6c2fbb;
  font-size: 15px;
  padding: 8px 30px;
  width: 100%;
  color: white;
}

.light-btn {
  border-radius: 5px;
  border: 1px solid #DBDBDB;
  background-color: #FCFCFC;
  font-size: 15px;
  padding: 8px 30px;
  display: flex;
  align-items: center;
}

.video-common-btn:not(:disabled):hover {
  background: #5e35b1;
}

form .signup-group .input-field {
  font-size: 14px;
  font-weight: normal;
  color: #3c4858;
  margin-bottom: 0px;
  transition: all 0.3s linear;
  position: absolute;
  bottom: 10px;
  margin-left: 12px;
}

.video_input_focus {
  bottom: 25px !important;
  font-size: 10px !important;
  color: #aeaeae !important;
}

.main_focus_input:focus + .input-field {
  color: #aeaeae;
  bottom: 25px;
  font-size: 10px;
}

.main_focus_input:focus {
  padding-bottom: 0;
}

.social-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-area > h6 {
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 15px;
}

.social-area .social-icon {
  display: flex;
}

.social-area .social-icon button img {
  width: 28px;
  height: 28px;
  border-radius: 2px;
}

.social-area .social-icon button {
  background: transparent;
  outline: none;
  border: none;
  display: inline-block;
  margin-left: 25px;
}

span.or-text {
  color: #aeaeae;
  display: block;
  text-align: center;
  position: relative;
  margin: 20px 0px;
  font-size: 15px;
}

span.or-text::before {
  content: "";
  display: inline-block;
  width: 45%;
  height: 1px;
  background-color: #dad8d8;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}

span.or-text::after {
  content: "";
  display: inline-block;
  width: 45%;
  height: 1px;
  background-color: #dad8d8;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.tc-pp-text {
  color: #aeaeae;
  font-size: 12px;
  margin-top: 22px;
  margin-bottom: 0px;
  text-align: center;
}

.tc-pp-text a {
  color: #aeaeae;
  text-decoration: underline;
}

.focusIn {
  padding-bottom: 0;
  font-size: 15px;
}

form .user-pic-text {
  text-align: center;
}

form .user-pic-text > img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-bottom: 15px;
}

form .user-pic-text > h5 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 2px;
}

form .user-pic-text > p {
  font-size: 14px;
}

.video-common-form .forgot-reset-pwd {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.video-common-form .forgot-reset-pwd > a {
  font-size: 12px;
  color: #6c2fbb;
}

/* signup, signin, resetpwd common css end*/

/* top header css start */

.navbar-dark .navbar-toggler-icon {
  background-repeat: no-repeat;
  background-size: cover;
}

.hbfilms-top-header .logo-or-search h1 {
  font-size: 25px;
}

.hbfilms-top-header .navbar-nav-2 {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.hbfilms-top-header .navbar-nav-2 > li {
  margin-left: 10px;
}

.hbfilms-top-header form {
  position: relative;
}

.hbfilms-top-header form input {
  /* width: 400px; */
  padding-left: 45px;
  font-size: 15px;
}

.hbfilms-top-header form button {
  position: absolute;
}

.hbfilms-top-header .navbar-nav-2 .notif-drop .dropdown-menu {
  right: 0;
  padding: 0;
  left: auto;
  top: 110%;
}

.dropdown-item {
  border-bottom: 1px solid #ccc;
  padding: 10px 15px;
  font-size: 12px !important;
}

.hbfilms-top-header .navbar-nav-2 li a img,
.hbfilms-top-header .navbar-nav-2 li a svg {
  width: 23px;
  height: 23px;
  object-fit: cover;
}

.hbfilms-top-header .navbar-nav-2 li a:hover svg {
  fill: #6c2fbb;
}

.hbfilms-top-header .header-logo {
  color: black !important;
  margin-right: 35px;
}

.hbfilms-top-header .navbar-nav-2 li:last-child img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.hbfilms-top-header .notif-drop a::after {
  display: none;
}

/* top header css end */

/* form - Upload Profile Pic, Short Bio, Select Category page css Start*/

.video-common-form .stepbystep-form-area img {
  height: 50px;
  margin-bottom: 35px;
}

.video-common-form .complete-profile h5 {
  font-size: 16px;
  font-weight: 600;
}

.video-common-form .complete-profile p {
  font-size: 14px;
}

.video-common-form .stepbystep-form-area span,
.video-common-form .skip-button {
  font-size: 15px;
}

.video-common-form .skip-button a {
  color: black;
  text-decoration: none;
}

.video-common-form .short-bio-form textarea {
  padding: 15px 10px 10px 10px;
  height: 100px;
  resize: none;
}

.video-common-form .short-bio-form label {
  top: 5px;
}

.video-common-form .short-bio-form .count-letter {
  font-size: 15px;
  text-align: right;
  display: block;
}

.select-category-form .form-check label {
  font-size: 14px;
}

.FF-scroll-modify {
  /*height: 115px;*/
  height: 350px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 1rem;
}

.FF-scroll-modify::-webkit-scrollbar {
  width: 6px;
}

.FF-scroll-modify::-webkit-scrollbar-track {
  background: transparent;
}

.FF-scroll-modify::-webkit-scrollbar-thumb {
  background: #ebebed;
  border-radius: 50px;
}

.FF-scroll-modify::-webkit-scrollbar-thumb:hover {
  background: #d9d9db;
}

/* form - Upload Profile Pic, Short Bio, Select Category page css End*/

/* Video publish footer */

.vid-publish-footer .row .other-btns {
  display: flex;
}

.vid-publish-footer .row .other-btns button {
  background: no-repeat;
  border: none;
  outline: none;
}

.vid-publish-footer .row .other-btns .share-btn {
  border: 1px solid #ffffff;
  border-radius: 4px;
  width: 130px;
  height: 46px;
  padding: 0px 15px;
  margin-left: 10px;
}

.vid-publish-footer .row .other-btns .share-btn span {
  color: #ffffff;
  font-weight: 600;
}

.vid-publish-footer .row .other-btns .share-btn img {
  width: 14px;
  height: 14px;
}

.vid-publish-footer .row .other-btns button img {
  width: 30px;
  height: 30px;
}

section.vid-publish-footer {
  background-color: #232c37;
  padding: 20px 0px;
  /*margin-top: 30px;*/
  position: fixed;
  width: 100%;
  bottom: 0;
}

@media (max-width: 991px) {
  section.vid-publish-footer {
    padding: 8px 0px !important;
  }
  .vjs-control.vjs-button {
    /* padding: 25px 0 !important; */
    font-size: 9.5px !important;
  }
}

.vid-publish-footer .row .footer-text {
  text-align: center;
  font-size: 20px;
  color: #ffffff;
}

.vid-publish-footer .row .pre-pub-btn button {
  background: #6e5eff;
  border: 1px solid #6e5eff;
  color: #fff;
  padding: 7px 0px;
  border-radius: 5px;
  font-size: 15px;
  outline: none;
  transition: all 0.2s linear;
  width: 200px;
}

.vid-publish-footer .row .pre-pub-btn .outline-button {
  background: transparent;
  border: 1px solid #6c2fbb;
  color: #6c2fbb;
}

.vid-publish-footer .row .pre-pub-btn .outline-button:hover {
  background-color: #6c2fbb;
  color: #ffffff;
}

.vid-publish-footer .row .pre-pub-btn button:hover {
  border-color: #6c2fbb;
}

/* Video publish footer */

/* custom css chandan */

.pos-relative {
  position: relative;
}

.custom-alert {
  color: #dc3545;
  font-size: 12px;
}

.custom-alert-border {
  border-color: #dc3545 !important;
}

button:disabled {
  opacity: 0.7;
  cursor: default;
}

a,
button {
  cursor: pointer;
}

input[type=file],

  /* FF, IE7+, chrome (except button) */

input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.videochart {
  margin-top: 10px;
  height: 45px;
  width: 90px;
}

.videochartcontainer {
  margin-top: 10px;
  width: 100px;
  height: 58px;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
/* *{
  scrollbar-width: auto;
  scrollbar-color: #808080 #ffffff;
} */

body {
  scrollbar-width: auto;
}

/* Chrome, Edge, and Safari */
body ::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

body ::-webkit-scrollbar-track {
  background: #ffffff;
}

body ::-webkit-scrollbar-thumb {
  background-color: #808080;
  /* border-radius: 10px; */
  border: 1px solid #ffffff;
}

.vjs-fullscreen-control [title="Fullscreen"] {
  display: none;
}

.imvd {
  width: 34px;
  position: absolute;
  top: 13px;
  left: 0;
  right: 0;
  /* object-fit: cover; */
  background: #fff;
  margin: 0 auto;
  padding: 10px;
  border-radius: 45%;
}

.gt {
  margin-top: 10px;
  height: 40px;
  width: 66px;
  background: #dfdfdf;
  transition: 0.3s all ease-in-out;
}

.ncs {
  display: none !important;
}

.video-component:hover .gt {
  background-color: #726bfa;
}

.video-component {
  /*padding: 6px;*/
  background: #fff;
  border: 1px solid #ccc;
  transition: 0.3s all ease-in-out;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
  word-break: break-all;
  position: fixed;
  padding-right: 1px;
  height: 100px;
  width: 100px;
  overflow: hidden;
  margin: 20px;
}

#tree-view:not(.active) .video-component.nodeSelected {
  /*background: lightskyblue;*/
  background: #ffb46e;
}

#tree-view .video-component.active {
  /*background: lightskyblue;*/
  background: #ffb46e;
}

.ngx-dropdown-container .ngx-dropdown-list-container ul li {
  font-size: inherit;
  cursor: pointer;
  display: block;
  padding: 3px 20px;
  clear: both;
  word-break: break-all;
}

.test-drive .video-component {
  left: 0;
}

/* .display-video + iframe
{
  display: none;
} */

node {
  cursor: pointer;
}

.overlay {
  background-color: #eee;
}

.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 1.5px;
}

.node text {
  font-size: 10px;
  font-family: sans-serif;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}

.link.conclusion {
  fill: none;
  stroke: lightblue;
  stroke-width: 2px;
}

.templink {
  fill: none;
  stroke: red;
  stroke-width: 3px;
}

.ghostCircle.show {
  display: block;
}

.ghostCircle,
.activeDrag .ghostCircle {
  display: none;
}

.iframechart {
  position: relative;
  width: 100px;
  height: 82px;
  display: none;
  z-index: 9;
}

.node-text {
  font-size: 11px;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  /* overflow: hidden; */
  /*width: 90px;*/
  height: 100%;
  display: block;
  word-break: break-word;
  padding: 0 5px;
}

.overlay-vid {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-create-interactive-button-red.custom-create-interactive-button.create-interactive-button {
  background: #444;
  border-color: #444;
}

.ngx-dropdown-container button .nsdicon-angle-down {
  right: 6px !important;
  position: absolute !important;
  float: right !important;
  font-size: 10px !important;
  top: 15px !important;
}

.ngx-dropdown-container .ngx-dropdown-button {
  padding: 10px 5px !important;
  font-size: 14px !important;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
}

.ngx-dropdown-container button .nsdicon-angle-down::before {
  height: 8px !important;
  width: 8px !important;
}


.vjs-has-started .vjs-control-bar.added_choices {
  bottom: 66px !important;
}
.miniPlayer .vjs-control-bar.added_choices {
  bottom: 0 !important;
}

.video-control-bar .added_choices {
  bottom: 66px !important;
}

::ng-deep .video-js .vjs-control-bar {
  transition: 0.4s ease-in-out all;
  /* z-index: 99; */
  z-index: 9999 !important;
  position: relative !important;
}

::ng-deep .vjs-fullscreen {
  z-index: 10000 !important;
}

::ng-deep .video-js.vjs-fullscreen .vjs-control-bar {
  display: flex !important;
  opacity: 1 !important;
  visibility: visible !important;
  transition: opacity 0.5s;
}

/* Keep the progress bar responsive */
::ng-deep .video-js .vjs-progress-control {
  flex-grow: 1;
  width: auto;
}

.added_choices_v2 .vjs-control.vjs-button[customchoices="yes"] {
  bottom: 28px;
  position: absolute;
  padding: 25px 5px;
  height: auto;
  width: 100%;
  max-width: 100%;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  z-index: 9;
  left: 0;
  display: flex;
  justify-content: center;
}

.added_choices_v2 .vjs-control.vjs-button[customchoices="yes"] .choice-card {
  background: #fff;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  overflow: hidden;
  display: block;
  max-width: 300px;
  width: 100%;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
}

.added_choices_v2 .vjs-control.vjs-button[customchoices="yes"] .choice-card:hover {
  border-color: #726bfa;
}

.added_choices_v2 .vjs-control.vjs-button[customchoices="yes"] .choice-card p {
  color: black;
  padding: 8px 2px;
  margin-bottom: 0;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.added_choices_v2 .vjs-control.vjs-button[customchoices="yes"] .choice-card img {
  height: 140px;
  width: 100%;
}

@media (max-width: 1024px) {
  .added_choices_v2 .vjs-control.vjs-button[customchoices="yes"] .choice-card img {
    height: 120px;
  }
}

@media (max-width: 768px) {
  .added_choices_v2 .vjs-control.vjs-button[customchoices="yes"] .choice-card img {
    height: 80px;
  }
  .testimonials.customers .owl-theme .owl-nav {
    display: block !important;
  }
}

@media (max-width: 425px) {
  .added_choices_v2 .vjs-control.vjs-button[customchoices="yes"] .choice-card img {
    height: 50px;
  }
}

.added_choices .vjs-control.vjs-button[customchoices="yes"] {
  position: absolute;
  background: #ccc;
  bottom: -66px;
  padding: 25px 5px;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  cursor: pointer;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
  z-index: 9;
  left: 0;
  height: auto;
  white-space: nowrap;
  max-width: 100%;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  text-overflow: ellipsis;
  overflow: hidden;
  color: inherit;
}

@media (min-width: 492px) and (max-width: 576px) {
  .added_choices .vjs-control.vjs-button[customchoices="yes"] {
    padding: 29px 5px;
    font-size: 16px !important;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .added_choices .vjs-control.vjs-button[customchoices="yes"] {
    padding: 29px 5px;
    font-size: 16px !important;
  }
}

.added_choices .vjs-control.vjs-button[customchoices="yes"]:hover {
  background-color: #726bfa !important;
  opacity: 0.9;
}

.vjs-control.vjs-button[custom-width="50"] {
  width: 50% !important;
}

.vjs-control.vjs-button:last-child {
  /*border-left: 1px solid rgb(190, 190, 190);*/
}

.vjs-control.vjs-button[custom-width="50"]:last-child {
  left: 50%;
  /*border-left: 1px solid rgb(190, 190, 190);*/
}

.vjs-control.vjs-button[custom-width="100"] {
  width: 100% !important;
}

.vjs-control.vjs-button[custom-width="25"] {
  width: 25% !important;
}

.vjs-control.vjs-button[custom-width="25"][custom-distance="distance2"] {
  left: 25% !important;
}

.vjs-control.vjs-button[custom-width="25"][custom-distance="distance3"] {
  left: 50% !important;
}

[custom-width="25"]:last-child {
  left: 75% !important;
  /*border-left: 1px solid rgb(190, 190, 190);*/
}

.vjs-control.vjs-button[custom-width="33"] {
  width: 33.333% !important;
}

[custom-width="33"][custom-distance="distance2"] {
  left: 33.333% !important;
}

.vjs-control.vjs-button[custom-width="33"]:last-child {
  left: 66.666%;
  /*border-left: 1px solid rgb(190, 190, 190);*/
}

.marker {
  position: relative;
  z-index: 99;
  background: yellow;
  width: 4px;
  height: 14px;
  border-radius: 10px;
  margin-top: -6px;
  /* margin-bottom: 33px; */
}

.animations {
  transition: 0.3s ease-in-out all;
}

.video-js .vjs-play-progress {
  background-color: #726bfa !important;
}

.video-js .vjs-play-progress:before {
  color: #726bfa !important;
}

.video-js .vjs-big-play-button {
  top: calc(50% - 18px) !important;
  left: 0 !important;
  right: 0 !important;
  font-size: 20px !important;
  line-height: 37px !important;
  height: 37px !important;
  width: 37px !important;
  border-radius: 50%;
  margin: 0 auto !important;
  background-color: white !important;
  z-index: 99;
}


.vjs-has-started .vjs-big-play-button {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
}


.vjs-paused .vjs-big-play-button {
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.5s;
}

.video-js .vjs-big-play-button::before {
  background: #726bfa;
  border-radius: 50%;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder::before {
  background: #726bfa;
  border-radius: 50%;
}

.video-js .vjs-pip-button {
  display: none !important;
}

.video-all-dimensions {
  width: 100%;
  height: 300px;
}

.video-all-edit-dimensions {
  width: 100%;
  height: 40vh;
}

.publishVideo-dimensions {
  width: 100%;
  height: 300px;
}

.previewVideo-dimensions {
  width: 100%;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #6c2fbb;
  background-color: #6c2fbb;
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: lightgrey;
  border-color: lightgrey;
}

#collapsibleNavbar .btn.shadow-none {
  background: #f5f5f5;
  padding: 4px 8px 4px;
  margin-left: 1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.owl-carousel .owl-nav button.owl-next {
  position: absolute !important;
  right: -10px !important;
  top: 30% !important;
  background: #fff !important;
  width: 30px !important;
  height: 30px !important;
  line-height: 20px !important;
  font-size: 24px !important;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5) !important;
  border-radius: 50% !important;
}

.owl-carousel .owl-stage {
  padding: 0 !important;
}

.owl-carousel .owl-nav button.owl-next span {
  position: absolute !important;
  top: 3px !important;
  right: 10px;
  font-size: 34px;
}

.owl-carousel .owl-nav button.owl-prev {
  position: absolute !important;
  left: -10px !important;
  top: 30% !important;
  background: #fff !important;
  width: 30px !important;
  height: 30px !important;
  line-height: 20px !important;
  font-size: 24px !important;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5) !important;
  border-radius: 50% !important;
}

.pop-Categories-slider.owl-carousel .owl-nav button.owl-prev,
.pop-Categories-slider.owl-carousel .owl-nav button.owl-next,
.interact-slider-start.owl-carousel .owl-nav button.owl-prev,
.interact-slider-start.owl-carousel .owl-nav button.owl-next {
  top: 50% !important;
}

.owl-carousel .owl-nav button.owl-prev span {
  position: absolute !important;
  top: 3px !important;
  right: 11px;
  font-size: 34px;
}

.test.owl-carousel .owl-nav button.owl-prev {
  border: 2px solid #727272;
  background: transparent !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 2px !important;
  font-size: 30px !important;
  position: absolute !important;
  top: 213px !important;
  right: 22px !important;
  left: auto !important;
  line-height: 22px !important;
}

.canvasjs-chart-credit {
  display: none !important;
}

.canvasjs-chart-canvas,
.canvasjs-chart-container {
  background-color: transparent !important;
}

.test.owl-carousel .owl-nav {
  margin-top: 0px;
}

.test.owl-carousel .owl-nav button.owl-next {
  border: 2px solid #727272;
  background: transparent !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 2px !important;
  font-size: 30px !important;
  position: absolute !important;
  top: 213px !important;
  line-height: 22px !important;
  right: -3px !important;
}

.test.owl-carousel .owl-nav button.owl-prev:disabled,
.test.owl-carousel .owl-nav button.owl-next:disabled {
  opacity: 0.7 !important;
}

.test.owl-carousel .owl-nav button.owl-prev span {
  color: #727272;
  position: absolute !important;
  top: -4px !important;
  left: 5px !important;
  font-size: 24px;
}

.test.owl-carousel .owl-nav button.owl-next span {
  color: #727272;
  position: absolute !important;
  top: -4px !important;
  left: 5px !important;
  font-size: 24px;
}

* {
  outline: none !important;
}

.pointer {
  cursor: pointer !important;
}

.custom-container {
  padding-left: 8%;
  padding-right: 8%;
}

.cateogyclass {
  border: 1px solid #6c2fbb;
  border-radius: 8px;
  padding: 4px 8px;
  cursor: pointer;
  /*color: #413e3e !important;*/
  color: #6c2fbb !important;
  margin-right: 5px;
  font-size: 12px;
  margin-bottom: 5px;
  display: none !important;
}

.cateogyclass.class0,
.needtoShow.cateogyclass,
.parentofdotdotButton {
  display: inline-block !important;
}

.needtoShow.cateogyclass {
  width: 100%;
}

.dotdotButton {
  border: none;
  background: transparent;
  display: inline-block !important;
}

.dotdotButton::after {
  display: none;
}

.parentofdotdotButton .dropdown-menu {
  padding: 0;
}

.parentofdotdotButton div a {
  display: block;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
}

.cateogyclass:hover {
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
}

.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  opacity: 1 !important;
}

.video-js {
  z-index: 9;
}

.over {
  position: absolute;
  right: 15px;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}

.over span {
  margin: 5px;
  color: #fff !important;
  font-size: 12px !important;
}

.over span img {
  width: auto !important;
  height: 14px !important;
  margin-top: -5px !important;
}

.child-wo.owl-theme .owl-dots .owl-dot span {
  width: 25px;
  height: 3px;
  background: #726bfa;
}

.child-wo.owl-theme .owl-dots .owl-dot.active span {
  background: #fff;
}

.child-wo.owl-theme .owl-dots {
  margin: 0;
  position: absolute;
  margin-top: -58px !important;
  z-index: 9;
  left: 0;
  right: 0;
}

img.rounded-circle {
  object-fit: cover;
}

.v-choice-carousel .owl-nav button.owl-next {
  position: absolute;
  right: -25px;
  top: 25%;
  font-size: 24px;
  color: #000;
  background: #fff;
  width: 30px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  line-height: 24px;
  height: 30px;
  border-radius: 50%;
}

.v-choice-carousel .owl-nav button.owl-prev {
  position: absolute;
  left: -25px;
  top: 25%;
  font-size: 24px;
  color: #000;
  background: #fff;
  width: 30px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  line-height: 24px;
  height: 30px;
  border-radius: 50%;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #726bfa;
  color: #000;
  text-decoration: none;
}

.profile-image {
  /*padding-top: 10px;*/
  border-radius: 13px;
  width: 70px;
  height: 70px;
  /*margin-right: 10px;*/
  margin: auto;
  text-align: center;
  color: #ffffff;
  background: #bd50d0;
  /*border-radius: 4px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  /*line-height: 40px;*/
  /* for square box */
  line-height: 50px;
  text-decoration: none;
}

.profile-image-pic {
  border-radius: 8px;
  margin-right: 10px;
  width: 45px;
  height: 45px;
}

.profile-image-nopic {
  width: 45px;
  height: 45px;
  /*padding-top: 10px;*/
  margin-right: 10px;
  text-align: center;
  color: #ffc266;
  background: #bd50d0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  text-decoration: none;
  /*line-height: 2;*/
}

.profile-image-sm {
  width: 35px;
  height: 35px;
  border-radius: 4px;
}

.public-vid-comments .video-js {
  /*height: 70vh;*/
  height: 0;
  padding-top: 50%;
}



/* Hide iOS default controls */
::ng-deep video::-webkit-media-controls {
  display: none !important;
}

::ng-deep video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

::ng-deep video::-webkit-media-controls-overlay-play-button {
  display: none !important;
}

::ng-deep *::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

::ng-deep *::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

::ng-deep *::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}

::ng-deep video::slotted::-webkit-media-controls-container {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-appearance: none !important;
}

::ng-deep video:host::shadow::-webkit-media-controls-container {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-appearance: none !important;
}


::ng-deep video::-webkit-media-controls-panel,
::ng-deep video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

.onMobilrPlayer{
  overflow: hidden !important;
}
::ng-deep .size-medium::part(button) {
  bottom: 80px !important;
}
/* Responsive CSS */

@media only screen and (max-width: 992px) {
  #previewVideoMobile {
    display: block !important;
  }

  .activeDemo {
    display: block !important;
    height: 100% !important;
    width:100%;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
  }

  /* ::ng-deep .activeDemo .video-js .vjs-control-bar {
    height: 5em !important;
  } */

  .isMobile .vjs-fullscreen-control[title="Fullscreen"] {
    display: none;
  }

  /* #previewVideo
{
  display: none!important;
} */
  .custom-height .vjs-tech,
  .custom-height {
    /* height: 100% !important; */
    min-height: auto !important;
  }
  .video-js .vjs-tech {
    height: auto !important;
  }
  .interact-vid-sec {
    padding: 40px 35px 0px 78px !important;
  }
  .cat-title {
    font-size: 15px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
  .cat-text {
    font-size: 10px !important;
  }
}

.ngx-dropdown-container .ngx-dropdown-list-container ul {
  max-height: 200px;
  overflow: auto;
}

.progress-circle {
  font-size: 12px;
  margin: 5px;
  position: relative;
  padding: 0;
  width: 3em;
  height: 3em;
  background-color: #f2e9e1;
  border-radius: 50%;
  line-height: 3em;
  float: left;
}

.progress-rule {
  margin-left: 3em;
  width: calc(100% - 3em - 10px);
  word-break: break-all;
  margin-bottom: 15px;
}

.progress-circle:after {
  border: none;
  position: absolute;
  top: 0.35em;
  left: 0.35em;
  text-align: center;
  display: block;
  border-radius: 50%;
  float: left;
  width: 2.3em;
  height: 2.3em;
  background-color: #f5f7f6;
  content: " ";
}

/* Text inside the control */

.progress-circle span {
  position: absolute;
  line-height: 3em;
  width: 3em;
  text-align: center;
  display: block;
  color: #f7172c;
  z-index: 2;
  font-weight: 600;
}

.left-half-clipper {
  /* a round circle */
  border-radius: 50%;
  width: 3em;
  height: 3em;
  position: absolute;
  clip: rect(0, 3em, 3em, 1.5em);
}

/* when p>50, don't clip left half*/

.progress-circle.over50 .left-half-clipper {
  clip: rect(auto, auto, auto, auto);
}

.value-bar {
  position: absolute;
  clip: rect(0, 1.5em, 3em, 0);
  width: 3em;
  height: 3em;
  border-radius: 50%;
  border: 0.45em solid #f7172c;
  /* background-color: #4D642D; */
  box-sizing: border-box;
}

.over50.value-bar {
  border: 0.45em solid #53777a;
  /* background-color: #4D642D; */
}

.progress-circle.over50 span {
  color: #53777a;
}

/* Progress bar filling the whole right half for values above 50% */

.progress-circle.over50 .first50-bar {
  /*Progress bar for the first 50%, filling the whole right half*/
  position: absolute;
  /*needed for clipping*/
  clip: rect(0, 5em, 5em, 2.5em);
  background-color: #53777a;
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

.video-js {
  overflow: hidden;
}

.progress-circle:not(.over50) .first50-bar {
  display: none;
}

a {
  text-decoration: none;
}

.navbar-dark .navbar-toggler {
  color: #444;
  border-color: #444;
  background: #444;
}

.landingPage .navbar-dark .navbar-toggler {
  color: #fff;
  border-color: #fff;
  background: transparent;
}

/* Progress bar rotation position */

.progress-circle.p0 .value-bar {
  display: none;
}

.progress-circle.p1 .value-bar {
  transform: rotate(4deg);
}

.progress-circle.p2 .value-bar {
  transform: rotate(7deg);
}

.progress-circle.p3 .value-bar {
  transform: rotate(11deg);
}

.progress-circle.p4 .value-bar {
  transform: rotate(14deg);
}

.progress-circle.p5 .value-bar {
  transform: rotate(18deg);
}

.progress-circle.p6 .value-bar {
  transform: rotate(22deg);
}

.progress-circle.p7 .value-bar {
  transform: rotate(25deg);
}

.progress-circle.p8 .value-bar {
  transform: rotate(29deg);
}

.progress-circle.p9 .value-bar {
  transform: rotate(32deg);
}

.progress-circle.p10 .value-bar {
  transform: rotate(36deg);
}

.progress-circle.p11 .value-bar {
  transform: rotate(40deg);
}

.progress-circle.p12 .value-bar {
  transform: rotate(43deg);
}

.progress-circle.p13 .value-bar {
  transform: rotate(47deg);
}

.progress-circle.p14 .value-bar {
  transform: rotate(50deg);
}

.progress-circle.p15 .value-bar {
  transform: rotate(54deg);
}

.progress-circle.p16 .value-bar {
  transform: rotate(58deg);
}

.progress-circle.p17 .value-bar {
  transform: rotate(61deg);
}

.progress-circle.p18 .value-bar {
  transform: rotate(65deg);
}

.progress-circle.p19 .value-bar {
  transform: rotate(68deg);
}

.progress-circle.p20 .value-bar {
  transform: rotate(72deg);
}

.progress-circle.p21 .value-bar {
  transform: rotate(76deg);
}

.progress-circle.p22 .value-bar {
  transform: rotate(79deg);
}

.progress-circle.p23 .value-bar {
  transform: rotate(83deg);
}

.progress-circle.p24 .value-bar {
  transform: rotate(86deg);
}

.progress-circle.p25 .value-bar {
  transform: rotate(90deg);
}

.progress-circle.p26 .value-bar {
  transform: rotate(94deg);
}

.progress-circle.p27 .value-bar {
  transform: rotate(97deg);
}

.progress-circle.p28 .value-bar {
  transform: rotate(101deg);
}

.progress-circle.p29 .value-bar {
  transform: rotate(104deg);
}

.progress-circle.p30 .value-bar {
  transform: rotate(108deg);
}

.progress-circle.p31 .value-bar {
  transform: rotate(112deg);
}

.progress-circle.p32 .value-bar {
  transform: rotate(115deg);
}

.progress-circle.p33 .value-bar {
  transform: rotate(119deg);
}

.progress-circle.p34 .value-bar {
  transform: rotate(122deg);
}

.progress-circle.p35 .value-bar {
  transform: rotate(126deg);
}

.progress-circle.p36 .value-bar {
  transform: rotate(130deg);
}

.progress-circle.p37 .value-bar {
  transform: rotate(133deg);
}

.progress-circle.p38 .value-bar {
  transform: rotate(137deg);
}

.progress-circle.p39 .value-bar {
  transform: rotate(140deg);
}

.progress-circle.p40 .value-bar {
  transform: rotate(144deg);
}

.progress-circle.p41 .value-bar {
  transform: rotate(148deg);
}

.progress-circle.p42 .value-bar {
  transform: rotate(151deg);
}

.progress-circle.p43 .value-bar {
  transform: rotate(155deg);
}

.progress-circle.p44 .value-bar {
  transform: rotate(158deg);
}

.progress-circle.p45 .value-bar {
  transform: rotate(162deg);
}

.progress-circle.p46 .value-bar {
  transform: rotate(166deg);
}

.progress-circle.p47 .value-bar {
  transform: rotate(169deg);
}

.progress-circle.p48 .value-bar {
  transform: rotate(173deg);
}

.progress-circle.p49 .value-bar {
  transform: rotate(176deg);
}

.progress-circle.p50 .value-bar {
  transform: rotate(180deg);
}

.progress-circle.p51 .value-bar {
  transform: rotate(184deg);
}

.progress-circle.p52 .value-bar {
  transform: rotate(187deg);
}

.progress-circle.p53 .value-bar {
  transform: rotate(191deg);
}

.progress-circle.p54 .value-bar {
  transform: rotate(194deg);
}

.progress-circle.p55 .value-bar {
  transform: rotate(198deg);
}

.progress-circle.p56 .value-bar {
  transform: rotate(202deg);
}

.progress-circle.p57 .value-bar {
  transform: rotate(205deg);
}

.progress-circle.p58 .value-bar {
  transform: rotate(209deg);
}

.progress-circle.p59 .value-bar {
  transform: rotate(212deg);
}

.progress-circle.p60 .value-bar {
  transform: rotate(216deg);
}

.progress-circle.p61 .value-bar {
  transform: rotate(220deg);
}

.progress-circle.p62 .value-bar {
  transform: rotate(223deg);
}

.progress-circle.p63 .value-bar {
  transform: rotate(227deg);
}

.progress-circle.p64 .value-bar {
  transform: rotate(230deg);
}

.progress-circle.p65 .value-bar {
  transform: rotate(234deg);
}

.progress-circle.p66 .value-bar {
  transform: rotate(238deg);
}

.progress-circle.p67 .value-bar {
  transform: rotate(241deg);
}

.progress-circle.p68 .value-bar {
  transform: rotate(245deg);
}

.progress-circle.p69 .value-bar {
  transform: rotate(248deg);
}

.progress-circle.p70 .value-bar {
  transform: rotate(252deg);
}

.progress-circle.p71 .value-bar {
  transform: rotate(256deg);
}

.progress-circle.p72 .value-bar {
  transform: rotate(259deg);
}

.progress-circle.p73 .value-bar {
  transform: rotate(263deg);
}

.progress-circle.p74 .value-bar {
  transform: rotate(266deg);
}

.progress-circle.p75 .value-bar {
  transform: rotate(270deg);
}

.progress-circle.p76 .value-bar {
  transform: rotate(274deg);
}

.progress-circle.p77 .value-bar {
  transform: rotate(277deg);
}

.progress-circle.p78 .value-bar {
  transform: rotate(281deg);
}

.progress-circle.p79 .value-bar {
  transform: rotate(284deg);
}

.progress-circle.p80 .value-bar {
  transform: rotate(288deg);
}

.progress-circle.p81 .value-bar {
  transform: rotate(292deg);
}

.progress-circle.p82 .value-bar {
  transform: rotate(295deg);
}

.progress-circle.p83 .value-bar {
  transform: rotate(299deg);
}

.progress-circle.p84 .value-bar {
  transform: rotate(302deg);
}

.progress-circle.p85 .value-bar {
  transform: rotate(306deg);
}

.progress-circle.p86 .value-bar {
  transform: rotate(310deg);
}

.progress-circle.p87 .value-bar {
  transform: rotate(313deg);
}

.progress-circle.p88 .value-bar {
  transform: rotate(317deg);
}

.progress-circle.p89 .value-bar {
  transform: rotate(320deg);
}

.progress-circle.p90 .value-bar {
  transform: rotate(324deg);
}

.progress-circle.p91 .value-bar {
  transform: rotate(328deg);
}

.progress-circle.p92 .value-bar {
  transform: rotate(331deg);
}

.progress-circle.p93 .value-bar {
  transform: rotate(335deg);
}

.progress-circle.p94 .value-bar {
  transform: rotate(338deg);
}

.progress-circle.p95 .value-bar {
  transform: rotate(342deg);
}

.progress-circle.p96 .value-bar {
  transform: rotate(346deg);
}

.progress-circle.p97 .value-bar {
  transform: rotate(349deg);
}

.progress-circle.p98 .value-bar {
  transform: rotate(353deg);
}

.progress-circle.p99 .value-bar {
  transform: rotate(356deg);
}

.progress-circle.p100 .value-bar {
  transform: rotate(360deg);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
}

.landingPage .owl-nav,
.treandingPage .owl-nav {
  opacity: 0 !important;
}

/* Highly Interactive Section CSS */

.landingPage .container-fluid:hover .owl-nav,
.treandingPage .container-fluid:hover .owl-nav {
  opacity: 1 !important;
}

.yeah .trending.interact-slider.owl-carousel .owl-nav button.owl-prev {
  position: absolute !important;
  right: 50px !important;
  top: -45px !important;
  background: transparent !important;
  width: 30px !important;
  height: 30px !important;
  line-height: 20px !important;
  font-size: 24px !important;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5) !important;
  border-radius: 0 !important;
  border: 1px solid #fff;
  left: auto !important;
}

.yeah .trending.interact-slider.owl-carousel .owl-nav button.owl-prev span,
.yeah .trending.interact-slider.owl-carousel .owl-nav button.owl-next span {
  color: #fff;
  font-size: 35px;
}

.yeah .trending.interact-slider.owl-carousel .owl-nav button.owl-next {
  position: absolute !important;
  right: 10px !important;
  top: -45px !important;
  background: transparent !important;
  width: 30px !important;
  height: 30px !important;
  line-height: 20px !important;
  font-size: 24px !important;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5) !important;
  border-radius: 0 !important;
  border: 1px solid #fff;
  left: auto !important;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.linear-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  height: 20px;
  position: relative;
  overflow: hidden;
  display: block;
}

/*.cdk-overlay-container {*/
/*  z-index: 1100 !important;*/
/*}*/

@font-face {
  font-family: "Mukta";
  src: url("../assets/fonts/Mukta-SemiBold.woff2") format("woff2"),
  url("../assets/fonts/Mukta-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mukta";
  src: url("../assets/fonts/Mukta-Regular.woff2") format("woff2"),
  url("../assets/fonts/Mukta-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mukta";
  src: url("../assets/fonts/Mukta-Medium.woff2") format("woff2"),
  url("../assets/fonts/Mukta-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mukta";
  src: url("../assets/fonts/Mukta-Bold.woff2") format("woff2"),
  url("../assets/fonts/Mukta-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mukta";
  src: url("../assets/fonts/Mukta-ExtraBold.woff2") format("woff2"),
  url("../assets/fonts/Mukta-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mukta";
  src: url("../assets/fonts/Mukta-ExtraLight.woff2") format("woff2"),
  url("../assets/fonts/Mukta-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mukta";
  src: url("../assets/fonts/Mukta-Light.woff2") format("woff2"),
  url("../assets/fonts/Mukta-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

html,
body {
  width: 100%;
  height: 100%;
  /* overflow-x: hidden; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f8fafb !important;
  /*font-family: "Mukta" !important;*/
  font-family: "Roboto" !important;
}
body.active-body {
  overflow: hidden;
}

.breadcrumb svg {
  fill: #7a2df9;
  margin-right: 20px;
  margin-top: 5px;
}

.gap-0 {
  gap: 0 !important
}

.gap-1 {
  gap: .25rem !important
}

.gap-2 {
  gap: .5rem !important
}

.gap-3 {
  gap: 1rem !important
}

.gap-4 {
  gap: 1.5rem !important
}

.gap-5 {
  gap: 3rem !important
}

.breadcrumb {
  display: flex;
  align-items: center;
  padding: 15px 0;
  margin-bottom: 0;
  background: transparent;
}

/*.breadcrumb svg {*/
/*  fill: #7a2df9;*/
/*  margin-right: 20px;*/
/*}*/

.breadcrumb a {
  font-weight: 400;
  font-size: 14px;
  /*color: #6c2fbb;*/
  color: #000000;
  padding-top: 5px;
  position: relative;
  text-decoration: none;
}

.breadcrumb span {
  font-weight: 400;
  font-size: 14px;
  /*color: #6c2fbb;*/
  color: #000000;
  padding-left: 8px;
  padding-right: 8px;
}

/*!*.breadcrumb a::after {*!*/
/*!*  position: absolute;*!*/
/*!*  top: 13px;*!*/
/*!*  left: -12px;*!*/
/*!*  content: "";*!*/
/*!*  width: 4px;*!*/
/*!*  height: 4px;*!*/
/*!*  background: #0f062b;*!*/
/*!*  border-radius: 50%;*!*/
/*!*}*!*/

@media (max-width: 425px) {
  .breadcrumb {
    padding: 15px 0;
  }
}


.light-scroll::-webkit-scrollbar {
  width: 6px;
}

.light-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.light-scroll::-webkit-scrollbar-thumb {
  background: #ebebed;
  border-radius: 50px;
}

.light-scroll::-webkit-scrollbar-thumb:hover {
  background: #d9d9db;
}


/*home page css*/
.customers .owl-carousel .owl-item img {
  width: fit-content !important;
}

.customers .owl-carousel .owl-nav button.owl-prev,
.customers .owl-carousel .owl-nav button.owl-next {
  width: 45px !important;
  height: 45px !important;
  border: 2px solid #fff !important;
  border-radius: 50% !important;
  background: transparent !important;
  color: #FFFFFF !important;
}

.customers .owl-carousel .owl-nav button.owl-next {
  position: absolute !important;
  top: 33% !important;
  transform: translateY(-33%) !important;
  right: 10% !important;
}


.customers .owl-carousel .owl-nav button.owl-next:hover,
.customers .owl-carousel .owl-nav button.owl-prev:hover {
  background: none !important;
}

.customers .owl-carousel .owl-nav button.owl-prev span,
.customers .owl-carousel .owl-nav button.owl-next span {
  font-size: 30px;
  line-height: 30px;
  right: 15px !important;
}

.customers .owl-carousel .owl-nav button.owl-prev {
  position: absolute !important;
  top: 33% !important;
  transform: translateY(-33%) !important;
  left: 10% !important;
}


.customers .owl-carousel .owl-next.disabled,
.customers .owl-carousel .owl-prev.disabled {
  opacity: 0 !important;
}

@media (max-width: 1700px) {
  .customers .owl-carousel .owl-nav button.owl-prev {
    left: 0;
  }

  .customers .owl-carousel .owl-nav button.owl-next {
    right: 0;
  }
}

@media (max-width: 768px) {
  .customers .owl-carousel .owl-nav button.owl-next,
  .customers .owl-carousel .owl-nav button.owl-prev {
    width: 30px;
    height: 30px;
  }

  .customers .owl-carousel .owl-nav button.owl-prev span,
  .customers .owl-carousel .owl-nav button.owl-next span {
    font-size: 26px;
    line-height: 26px;
  }

  .customers .owl-carousel .owl-nav button.owl-prev {
    top: 31%;
    transform: translateY(-31%);
  }

  .customers .owl-carousel .owl-nav button.owl-next {
    top: 31%;
    transform: translateY(-31%);
  }
}

@media (max-width: 768px) {
  .customers .owl-theme .owl-nav {
    display: none;
  }
}

@media (max-width: 480px) {
  .customers .owl-carousel .owl-nav button.owl-prev {
    top: 27%;
    transform: translateY(-27%);
  }

  .customers .owl-carousel .owl-nav button.owl-next {
    top: 27%;
    transform: translateY(-27%);
  }
}



@media (max-width: 420px) {

  .customers .owl-carousel .owl-nav button.owl-prev {
    top: 24%;
    transform: translateY(-24%);
  }

  .customers .owl-carousel .owl-nav button.owl-next {
    top: 24%;
    transform: translateY(-24%);
  }

  .customers .owl-carousel .owl-nav button.owl-next,
  .customers .owl-carousel .owl-nav button.owl-prev {
    width: 25px;
    height: 25px;
  }

  .customers .owl-carousel .owl-nav button.owl-prev span,
  .customers .owl-carousel .owl-nav button.owl-next span {
    font-size: 22px;
    line-height: 22px;
  }
}

/*end home page css*/


/*tree dialog video*/

@media (max-width: 1024px) {
  .cdk-overlay-pane.video-tree-dialog {
    max-width: 100% !important;
    margin: 0 100px !important;
  }
}
@media (max-width: 768px) {
  .cdk-overlay-pane.video-tree-dialog {
    margin: 0 40px !important;
  }
}

@media (max-width: 425px) {
  .cdk-overlay-pane.video-tree-dialog {
    margin: 0 !important;
  }
}

/*tree dialog video*/

.miniPlayer .vjs-button {
  display: none !important;
}

/*my groups css*/

.mygroup .owl-carousel .owl-nav button.owl-prev {
  left: -48px !important;
  top: 23% !important;
  background: #F2F2F2 !important;
  border-radius: 9px !important;
  box-shadow: none !important;
}

.mygroup .owl-carousel .owl-nav button.owl-next {
  right: -48px !important;
  top: 23% !important;
  background: #F2F2F2 !important;
  border-radius: 9px !important;
  box-shadow: none !important;
}

.vjs-fade-out {
  display: block;
  visibility: hidden;
  opacity: 0 !important;

  -webkit-transition: visibility 1.5s, opacity 1.5s;
  -moz-transition: visibility 1.5s, opacity 1.5s;
  -ms-transition: visibility 1.5s, opacity 1.5s;
  -o-transition: visibility 1.5s, opacity 1.5s;
  transition: visibility 1.5s, opacity 1.5s;

  /* Wait a moment before fading out the control bar */
  -webkit-transition-delay: 2s;
  -moz-transition-delay: 2s;
  -ms-transition-delay: 2s;
  -o-transition-delay: 2s;
  transition-delay: 2s;
}

#treeContent .drag-scroll-content {
  max-height: calc(100vh - 195px);
}


.drag-scroll-content::-webkit-scrollbar {
  width: 6px;
}

.drag-scroll-content::-webkit-scrollbar-track {
  background: transparent;
}

.drag-scroll-content::-webkit-scrollbar-thumb {
  background: #ebebed;
  border-radius: 50px;
}

.drag-scroll-content::-webkit-scrollbar-thumb:hover {
  background: #d9d9db;
}


/* popover start */
body.shepherd-enabled {
  overflow: hidden;
}

.shepherd-button {
  background: #673ab7;
  color: #fff;
}

.shepherd-button:not(:disabled):hover {
  background: #512da8;
  color: #fff;
}

.shepherd-has-title .shepherd-content .shepherd-header {
  background: transparent;
}

.shepherd-title {
  font-weight: 500;
}

.shepherd-element[data-popper-placement^=top] > .shepherd-arrow:before {
  box-shadow: 1px 1px 1px 0 #d1c4e9;
}

.shepherd-element[data-popper-placement^=right] > .shepherd-arrow:before {
  box-shadow: -1px 1px 1px 0 #d1c4e9;
}

.shepherd-element[data-popper-placement^=left] > .shepherd-arrow:before {
  box-shadow: 1px -1px 1px 0 #d1c4e9;
}

.shepherd-element[data-popper-placement^=bottom] > .shepherd-arrow:before {
  box-shadow: -1px -1px 1px 0 #d1c4e9;
}

.shepherd-enabled.shepherd-element {
  border: 1px solid #d1c4e9;
  /*z-index: 99 !important;*/
}

.popover {
  border-color: #d1c4e9;
  max-width: 400px;
}

.bs-popover-auto[x-placement^=left] > .arrow::before, .bs-popover-left > .arrow::before {
  border-left-color: #d1c4e9;
}

.bs-popover-auto[x-placement^=top] > .arrow::before, .bs-popover-top > .arrow::before {
  border-top-color: #d1c4e9;
}

.bs-popover-auto[x-placement^=right] > .arrow::before, .bs-popover-right > .arrow::before {
  border-right-color: #d1c4e9;
}

.bs-popover-auto[x-placement^=bottom] > .arrow::before, .bs-popover-bottom > .arrow::before {
  border-bottom-color: #d1c4e9;
}


/*full screen modal*/
.mat-dialog-content {
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.fullscreen-dialog {
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  height: 100% !important;
}

.fullscreen-dialog .mat-dialog-content {
  max-height: 100vh !important;
}


/*blogs*/
.blog-content p:empty {
  display: flex;
  margin-bottom: 2rem;
}

.blog-content p, .blog-content ol li, .blog-content ul li {
  font-size: 18px;
  /*color: #000000;*/
}

.blog-content ol li p, .blog-content ul li p {
  display: inline;
}

.blog-content h5 {
  /*font-weight: 700;*/
  /*font-size: 30px;*/
  /*line-height: 36px;*/
  color: #6c2fbb;
  margin-top: 20px;
}

.blog-content ol, .blog-content ul {
  margin-left: 1rem;
  list-style-position: inside;
}

.blog-content ol li, .blog-content ul li {
  margin-bottom: .5rem;
}

.blog-content img,
.blog-content p img {
  max-width: 100%;
}


/*video choice*/

.video_choices_wrapper {
  width: 100%;
  /*height: 100%;*/
  position: absolute;
  top: 0;
  bottom: 40px;
  /*overflow: hidden;*/
  display: flex;
  align-items: flex-end;
  visibility: hidden;
}



.video_choices {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 40px;
  z-index: 9;
  padding: 0 15px;
  width: 100%;
}

.video_choices_v2 {
  position: unset !important;
  /*margin-bottom: 40px;*/
}

.video_choices .actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.video_choices .choice-box {
  display: flex;
  align-items: stretch;
  justify-content: center;
  column-gap: 20px;
  width: 100%;
  /*height: 175px;*/
}

.video_choices .choice-box .choice-card-wrapper {
  width: 100%; /* Occupy the entire width of the parent */
  padding-top: 13.5%; /* Set the aspect ratio (height/width * 100) */
  position: relative;
}

.video_choices .choice-box .choice-card {
  background: #fff;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  overflow: hidden;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  /*max-width: 300px;*/
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 50%);
  margin: 3px 0;
  /*position: relative;*/

}

.video_choices .choice-box .choice-card .choice-img {
  height: calc(100% - 25px);
  /*max-height: 145px;*/
  width: 100%;
  overflow: hidden;
  /*height: auto;*/
}

.video_choices .choice-box .choice-card p {
  padding: 5px;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 16px;
  color: black;
}

.video_choices .choose-label {
  border-radius: 3px;
  padding: 4px;
  background: rgb(43 51 63 / 100%);
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}

.video_choices .vc-share-btn {
  height: 55px;
  width: 215px !important;
  background: #6c2fbb !important;
  padding: 16px 45px;
  font-size: 16px !important;
}

.video_choices.added_choices_v2 {
  opacity: 1;
  visibility: visible;
  transition: opacity 1s ease-in;
}

.video_choices.added_choices_v2 {
  background: rgb(0 0 0 / 20%);
  box-shadow: 0 0 0 100vh rgb(0 0 0 / 20%);
}

.video_choices.added_choices_v2 .choice-box .choice-card:hover {
  z-index: 1;
  box-shadow: 0 0 0 100vh rgb(0 0 0 / 20%);
  border: 3px solid #D9D9D9;
  margin: 0;
}

.video_choices.added_choices_v2 .choice-box .choice-card a.viewed:before {
  content: " ";
  background-image: url('../assets/main-video/green-check-circle.png');
  background-size: 30px 30px;
  width: 30px;
  height: 30px;
  /*top: 2px;*/
  /*right: 5px;*/
  right: 0;
  position: absolute;
}

.ghost-choice-card {
  visibility: hidden;
}

.miniPlayer .video_choices_v2 {
  display: none;
}

.miniPlayer .video_questionnaire {
  display: none;
}

@media (max-width: 1440px) {
  /*.video_choices .choice-box .choice-card {*/
  /*  max-width: 280px;*/
  /*}*/
  /*.video_choices .choice-box .choice-card .choice-img {*/
  /*  max-height: 130px;*/
  /*}*/
}

@media (max-width: 1200px) {
  /*.video_choices .choice-box .choice-card .choice-img {*/
  /*  max-height: 120px;*/
  /*}*/
}

@media (max-width: 1000px) {
  /*.video_choices .choice-box .choice-card .choice-img {*/
  /*  max-height: 110px;*/
  /*}*/
}

@media (max-width: 800px) {
  /*.video_choices .choice-box .choice-card .choice-img {*/
  /*  max-height: 100px;*/
  /*}*/
}

@media (max-width: 768px) {
  /*.video_choices .choice-box .choice-card {*/
  /*  max-width: 200px;*/
  /*}*/
  /*.video_choices .choice-box .choice-card .choice-img {*/
  /*  max-height: 70px;*/
  /*}*/
  .video_choices .vc-share-btn {
    width: auto !important;
    height: auto;
    padding: 15px 20px;
  }

  .video_choices .vc-cmefy-btn {
    width: 150px;
  }

  .video_choices .choice-box .choice-card .choice-img {
    height: calc(100% - 20px);
  }

  .video_choices .choice-box .choice-card p {
    font-size: 14px;
    padding: 3px;
  }
}

@media (max-width: 600px) {

  .video_choices {
    gap: 6px;
  }

  .video_choices.added_choices_v2 .choice-box .choice-card:hover {
    border: none;
  }

  .video_choices .choose-label {
    font-size: 14px;
  }

  .video_choices .vc-share-btn {
    width: auto !important;
    height: auto;
    padding: 10px 15px;
    font-size: 14px !important;
  }

  .video_choices .vc-cmefy-btn {
    width: 100px;
  }

  /*.video_choices .choice-box .choice-card .choice-img {*/
  /*  max-height: 60px;*/
  /*}*/
}

@media (max-width: 512px) {
  /*.video_choices .choice-box .choice-card .choice-img {*/
  /*  max-height: 50px;*/
  /*}*/

}

@media (max-width: 425px) {
  /*.video_choices .choice-box .choice-card .choice-img {*/
  /*  max-height: 40px;*/
  /*}*/
}

.custom-range::-webkit-slider-thumb {
  background: #7a2df9;
  box-shadow: none !important;
}

.custom-range::-moz-range-thumb {
  background: #7a2df9;
  box-shadow: none !important;
}

.custom-range::-ms-thumb {
  background: #7a2df9;
  box-shadow: none !important;
}

.custom-range::-webkit-slider-thumb:active {
  background: #726bfa;
}

.custom-range::-moz-range-thumb:active {
  background: #726bfa;
}

.custom-range::-ms-thumb:active {
  background: #726bfa;
}

.ngx-editor li {
  list-style-position: inside;
}

.ngx-editor li p {
  display: inline;
}

.email-preview li {
  list-style-position: inside;
}

.email-preview li p {
  display: inline;
}

.vjs-record-button.vjs-control.vjs-button.vjs-icon-record-start::before {
  color: #dc3545;
}

.vjs-icon-record-start {
  display: none !important;
}

.vjs-icon-record-stop {
  display: none !important;
}

.tooltip.show {
  z-index: 1111 !important;
}

.civ-profile {
  border-radius: 4px;
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.civ-no-profile {
  width: 30px;
  height: 30px;
  color: #ffc266;
  background: #bd50d0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 5px;
}


.attachments {
  margin: 1rem 0;
  display: flex;
  /*flex-direction: column;*/
  /*justify-content: center;*/
  align-items: center;
}

.attachments .attachments-item {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.attachments .attachments-item:hover {
  background: #eaeded;
}

.attachments .attachments-item img {
  height: 80px;
  width: 80px;
  object-fit: cover;
}

.attachments .attachments-item .delete-btn {
  display: none;
  text-align: center;
  padding: 2px 5px;
  background: #D9D9D9;
  border-radius: 100%;
  position: absolute;
  line-height: 1;
  right: -10px;
  top: -10px;
}

.attachments .attachments-item:hover .delete-btn {
  display: block;
}

/*drag drop nodes new*/
.rearrange .node-action {
  visibility: hidden;
  opacity: 0;
}

.rearrange .droppable.can-drop {
  fill: #ffc266;
}

.rearrange .droppable {
  stroke: #28a745;
  filter: drop-shadow(0 0 4px lightgreen);
  stroke-dasharray: 3 3;
}

.rearrange .not-droppable {
  stroke: #dc3545;
  filter: drop-shadow(0 0 4px lightcoral);
  stroke-dasharray: 3 3;
}

.rearrange .active-drag {
  stroke: #6c2fbb;
  stroke-dasharray: 3 3;
}


.rearrange .video-component.droppable-node {
  border: 2px dotted #28a745;
  box-shadow: 0 0 4px lightgreen;
}

.rearrange foreignObject.droppable {
  background: #ffc266;
  border: 2px dotted #28a745;
  box-shadow: 0 0 4px lightgreen;
}

.rearrange .video-component.max-node {
  border: 2px dotted #dc3545;
  box-shadow: 0 0 4px lightcoral;
}

.drop-node:not(.max-node) {
  background: #ffc266;
  border-color: transparent !important;
  box-shadow: none !important;
}

.drag-node {
  border-color: #6c2fbb !important;
  box-shadow: none !important;
}

/*end drab drop new*/

/*new pathways highlight*/
.pathways .active {
  fill: #ffb46e;
}

.pathways .active-background {
  fill: #6c2fbb;
}

.pathways .active-text {
  fill: #fff;
}

.hover-box {
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.5));
}

.pathways .active-pathways, .most-common-choice {
  fill: #d3c1eb;
}

.add-pathways .node-action {
  visibility: hidden;
  opacity: 0;
}

.add-pathways:not(.pathways) .nodeSelected {
  fill: #ffb46e;
}

/*end new pathways highlight*/

/*search input group */
.search-input-group {
  position: relative;
}

.search-input-group input {
  padding-left: 32px;
}

.search-input-group .fa-search {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  color: #495057a3;
}

/*video edit*/
.video-edit .ng5-slider {
  /*margin: 16px 0 15px !important;*/
  margin-top: 1rem !important;
  height: 50px;
}

.video-edit .ng5-slider .ng5-slider-pointer {
  top: 0 !important;
  background-color: #672bff !important;
  width: 20px !important;
  height: 40px !important;
  outline: none !important;
}

.video-edit .ng5-slider .ng5-slider-pointer.ng5-slider-pointer-max {
  border: 3px solid #672bff;
  border-radius: 0 5px 5px 0;
  border-left: none;
}

.video-edit .ng5-slider .ng5-slider-pointer.ng5-slider-pointer-min {
  border: 3px solid #672bff;
  border-radius: 5px 0 0 5px;
  border-right: none;
}

.video-edit .ng5-slider .ng5-slider-bubble {
  bottom: 2px !important;
  /* visibility: hidden !important; */
}

.video-edit .ng5-slider .ng5-slider-pointer:after {
  top: 8px !important;
  left: 50% !important;
  content: "" !important;
  background: white !important;
  display: block !important;
  background-size: 28px 28px !important;
  height: 20px !important;
  width: 2px !important;
}

.video-edit .ng5-slider .ng5-slider-selection {
  z-index: 2;
  background-color: #9575cd !important;
  border: 3px solid #672bff;
  color: white;
  text-align: center;
  padding-top: 5px;
  font-size: 14px;
}

.video-edit .ng5-slider .ng5-slider-bar {
  height: 40px !important;
}

.video-edit .ng5-slider .ng5-slider-floor, .video-edit .ng5-slider .ng5-slider-ceil {
  display: none;
}

.video-edit .ng5-slider .ng5-slider-model-value, .video-edit .ng5-slider .ng5-slider-model-high {
  top: 40px;
}


/*video-editor*/

.video-editor #text-container {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.video-editor #text-container.active {
  display: inline-block;
}

.video-editor .text-overlay {
  position: absolute;
  user-select: none;
  padding: 5px;
}

.video-editor .text-overlay.active {
  border: 1px dashed var(--danger);
  width: max-content;
  max-width: 100%;
}

.video-editor .video-controls mat-slider {
  width: 400px;
}

@media (max-width: 540px) {
  .video-editor .video-controls mat-slider {
    width: 280px;
  }
}
@media (max-width: 360px) {
  .video-editor .video-controls mat-slider {
    width: 230px;
  }
}

.video-editor .mat-slider-thumb {
  background-color: var(--purple-2) !important;
}

.video-editor .mat-slider-thumb:active,
.video-editor .mat-slider-thumb:focus {
  background-color: var(--purple-2) !important;
}

.video-editor .mat-slider-thumb-label {
  background-color: var(--purple-2) !important;
}

.video-editor .mat-slider-thumb-label-text {
  color: white !important;
}

.video-editor .mat-slider-track-fill {
  background-color: var(--purple-2) !important;
}

/*video editor timeline*/
.timeline-wrapper .timeline-item {
  position: absolute;
  height: 100%;
  width: 4px;
  background-color: #333;
  cursor: pointer;
}

.timeline-wrapper .timeline-marker {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: var(--danger);
  z-index: 2;
  cursor: pointer;
}

.timeline-wrapper .timeline-marker img {
  position: absolute;
  top: -12px;
  left: -3.5px;
  color: var(--danger);
  z-index: 2;
  cursor: pointer;
  user-select: none;
}

.timeline-wrapper .handle {
  position: absolute;
  width: 10px;
  height: 100%;
  background-color: var(--purple-2);
  z-index: 1;
}

.timeline-wrapper .handle.left {
  left: 0;
  cursor: w-resize;
}

.timeline-wrapper .handle.right {
  right: 0;
  cursor: e-resize;
}

.timeline-wrapper .trim-bar {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--purple-2);
  opacity: 0.5;
  z-index: 0;
}

.timeline-wrapper .timeframe {
  position: absolute;
  top: -32px;
  left: 0;
  display: flex;
  z-index: 9;
  user-select: none;
}

.timeline-wrapper .timeframe span {
  position: absolute;
  font-size: 14px;
  color: gray;
}

.mat-tooltip {
  user-select: none;
  font-size: 12px !important;
}

/*!*custom popup on hover*!*/
/*.custom-shepherd-container {*/
/*  position: relative;*/
/*  display: inline;*/
/*}*/

/*.custom-shepherd-container:hover .custom-shepherd {*/
/*  display: block;*/
/*}*/

/*.custom-shepherd {*/
/*  display: none;*/
/*  position: absolute;*/
/*  background: white;*/
/*  z-index: 999;*/
/*  will-change: transform;*/
/*  top: 0;*/
/*  left: 0;*/
/*  transform: translate(-150px, 25px);*/
/*  width: max-content;*/
/*}*/

.badge-box {
  background: mediumpurple;
  width: 6.4em;
  height: 6.4em;
  border-radius: 20%;
  display: inline-block;
  transition: all 0.2s ease;
  box-shadow: 8px 10px 12px 3px rgba(0, 0, 0, 0.2);
  position: relative;
}

.badge-box .badge-rounded {
  width: 70px;
  height: 70px;
  background: white;
  position: absolute;
  margin: 15px 16px auto;
  z-index: 10;
  border-radius: 30%;
  box-shadow: 8px 8px 8px 2px rgba(0, 0, 0, 0.23);
}

.badge-box img {
  background: transparent;
  padding: 10px;
  border-radius: 20%;
  width: 100% !important;
  height: 100% !important;
}

.badge-box-sm {
  background: mediumpurple;
  /*width: 2.4em;*/
  /*height: 2.4em;*/
  padding: 0 7px;
  border-radius: 20%;
  display: inline-block;
  transition: all 0.2s ease;
  box-shadow: 4px 5px 6px 3px rgba(0, 0, 0, 0.2);
  position: relative;
}

.badge-box-sm .badge-rounded {
  width: 20px;
  height: 20px;
  background: white;
  margin: 7px auto;
  z-index: 10;
  border-radius: 20%;
  box-shadow: 5px 5px 5px 2px rgba(0, 0, 0, 0.23);
}

.badge-box-sm img {
  background: transparent;
  padding: 3px;
  border-radius: 20% !important;
  width: 100% !important;
  height: 100% !important;
  min-width: 100% !important;
  vertical-align: baseline;
}

.opacity-0 {
  opacity: 0 !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

@media (min-width: 1400px) {
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
}

.theme-scrollbar::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.theme-scrollbar::-webkit-scrollbar{
	width: 12px;
	background-color: #F5F5F5;
}

.theme-scrollbar::-webkit-scrollbar-thumb{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #808181;
}

.mdc-dialog__container .mdc-dialog__surface {
  padding: 20px
}

.modal{
  overflow: auto;
}

.btnCancelModal{
  background: white !important;
  color: #6c2fbb !important;
  border: 1px solid #6c2fbb !important;
}

.btnCancelDeleteModal{
  background: white !important;
  color: #dc3545 !important;
  border: 1px solid #dc3545 !important; 
}